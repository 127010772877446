import React, {useEffect, useState} from 'react';
import Admin from "layouts/admin";
import {deletes, get, patch} from "../../../class/helper";
import Select from "react-select";
import {useHistory, useParams} from "react-router-dom";
import {toast} from "react-hot-toast";

const NewUser = () => {
	let history = useHistory()
	let {user} = useParams()

	const [firstname, setFirstname] = useState("");
	const [lastname, setLastname] = useState("");
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [admin, setAdmin] = useState(false);

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	const [packages, setPackages] = useState([]);
	const [packagesSelected, setPackagesSelected] = useState([]);
	const [packagesSearch, setPackagesSearch] = useState("");

	const [achievements, setAchievements] = useState([]);
	const [achievementsSelected, setAchievementsSelected] = useState([]);
	const [achievementsSearch, setAchievementsSearch] = useState("");

	useEffect(() => {
		setLoading(true)
		get("/admin/users/" + user, true).then(r => {
			if (r.error) {
				toast.error(r.data)
				setError(r.data)
			} else {
				let curUser = r.data.user

				setFirstname(curUser.firstname)
				setLastname(curUser.lastname)
				setUsername(curUser.firstname)
				setAdmin(curUser.admin)

				setPackagesSelected(curUser.packages.map(pkg => MakeOption(pkg)))
				setAchievementsSelected(curUser.achievements.map(curAchieve => MakeOption(curAchieve)))
			}
		})

		get("/admin/packages", true).then(r => {
			if (r.error) {
				toast.error(r.data)
				setError(r.data)
			} else {
				setPackages(r.data.packages)
			}
		})

		get("/admin/achievements", true).then(r => {
			if (r.error) {
				toast.error(r.data)
				setError(r.data)
			} else {
				setAchievements(r.data.achievements)
			}
		})
		setLoading(false)
	}, []);

	let handleDelete = (e) => {
		e.preventDefault()

		deletes(`/admin/users/${user}`, true).then((data) => {
			if (data.error) {
				toast.error(data.data)
			}
			history.push("/admin/users")
		})
	}

	const handleSubmit = (e) => {
		e.preventDefault()

		let packagesSelectedParsed = []
		if (packagesSelected?.length > 0) {
			packagesSelected.forEach(packageSelected => {
				packagesSelectedParsed.push(packageSelected.value)
			})
		}

		let achievementsSelectedParsed = []
		if (achievementsSelected?.length > 0) {
			achievementsSelected.forEach(packageSelected => {
				achievementsSelectedParsed.push(packageSelected.value)
			})
		}

		patch(`/admin/users/${user}`, {
			firstname,
			lastname,
			username,
			password,
			admin,
			packages: packagesSelectedParsed,
			achievements: achievementsSelectedParsed,
		}, true, 201).then(r => {
			if (r.error) {
				toast.error(r.data)
				setError(r.data)
			}
			history.push("/admin/users")
		})
	};

	let fields = [
		{
			name: "firstname",
			input: setFirstname,
			type: "text",
			value: firstname
		}, {
			name: "lastname",
			input: setLastname,
			type: "text",
			value: lastname
		}, {
			name: "username",
			input: setUsername,
			type: "text",
			value: username
		}, {
			name: "password",
			input: setPassword,
			type: "password",
			value: password
		}
	]

	function MakeOption(x) {
		return {value: x._id, label: x.title};
	}

	return (
		<Admin title={"Edit User"}>
			<form className="flex flex-col rounded overflow-hidden py-2 px-2" onSubmit={handleSubmit}>
				<div className={"flex flex-col lg:flex-row"}>
					<div className={"flex flex-col mr-auto gap-4"}>
						{fields.map((field, index) => (
							<div className={"flex flex-col"} key={index}>
								<label className="font-medium tracking-wide text-gray-700 text-md" htmlFor={field.name}>{field.name.charAt(0).toUpperCase() + field.name.slice(1)}</label>
								<input id={field.name} name={field.name} className="items-center font-normal h-12 px-4 w-96 bg-gray-100 text-gray-700 mt-2 rounded focus:outline-none focus:ring-1 ring-blue-600" type={field.type} onChange={(e) => field.input(e.target.value)} value={field.value} placeholder={field.name.charAt(0).toUpperCase() + field.name.slice(1)}/>
							</div>
						))}
					</div>
					<div className={"flex flex-col mr-auto gap-5"}>
						<div className={"w-96"}>
							<label className="font-medium tracking-wide text-gray-700 text-md pr-2" htmlFor={"packages"}>Packages</label>
							{packages?.length > 0 ?
								<Select isMulti value={packagesSelected} name="packages" options={packages.map(curPackage => MakeOption(curPackage))} classNamePrefix="select" closeMenuOnSelect={false} onInputChange={(value) => setPackagesSearch(value)} inputValue={packagesSearch} onChange={(selected) => {
									setPackagesSelected(selected)
								}}/>
								:
								<div className="flex font-medium tracking-wide text-gray-700 text-md bg-gray-200 rounded p-8 justify-center items-center mt-2">There are no packages available</div>
							}
						</div>
						<div className={"w-96"}>
							<label className="font-medium tracking-wide text-gray-700 text-md pr-2" htmlFor={"achievements"}>Achievements</label>
							{achievements?.length > 0 ?
								<Select isMulti value={achievementsSelected} name="achievements" options={achievements.map(achievement => MakeOption(achievement))} classNamePrefix="select" closeMenuOnSelect={false} onInputChange={(value) => setAchievementsSearch(value)} inputValue={achievementsSearch} onChange={(selected) => {
									setAchievementsSelected(selected)
								}}/>
								:
								<div className="flex font-medium tracking-wide text-gray-700 text-md bg-gray-200 rounded p-8 justify-center items-center mt-2">There are no achievements available</div>
							}
						</div>
						<div className={"flex flex-row items-center"}>
							<label className="font-medium tracking-wide text-gray-700 text-md pr-2" htmlFor={"admin"}>Is the user an admin?</label>
							<input id="admin" name="admin" className="bg-gray-500 text-gray-300" type={"checkbox"} onChange={(e) => setAdmin(e.target.checked)} checked={admin}/>
						</div>
						<div className={"flex flex-row mt-auto ml-auto w-64 justify-end items-center"}>
							<button className="flex items-center justify-center rounded-sm text-xl font-medium bg-red-700 hover:bg-red-800 text-gray-100 hover:text-gray-200 focus:outline-none focus:ring-0 ml-auto mt-8 w-28 h-12" onClick={handleDelete}>Delete</button>
							<button className="flex items-center justify-center rounded-sm text-xl font-medium bg-blue-700 hover:bg-blue-800 text-gray-100 hover:text-gray-200 focus:outline-none focus:ring-0 ml-auto mt-8 w-28 h-12" type={"submit"}>Update</button>
						</div>
					</div>
				</div>
			</form>
		</Admin>
	);
};


export default NewUser;
