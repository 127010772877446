import React, {useEffect, useState} from 'react';
import App from "layouts/app";
import {get} from "../../../class/helper";
import {Link, useParams} from "react-router-dom";
import {RotateSpinner} from "react-spinners-kit";

const Package = () => {
		const [pkg, setPackage] = useState(null);
		const [error, setError] = useState(null);
		const [loading, setLoading] = useState(false);

		const {package: pkgID} = useParams()

		useEffect(() => {
			get("/packages/" + pkgID, true)
				.then(r => {
					if (r.error) {
						setError(r.data)
						setLoading(false)
					} else {
						setPackage(r.data.package)
						setLoading(false)
					}
				})
		}, [pkgID]);


		return (
			<App title={loading ? "Loading..." : (pkg?.title ? pkg.title : "Error")}>
				{!loading ?
					pkg?.lessons ?
						<div className={"grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 p-2"}>
							{pkg?.lessons?.map((lesson, i) => {
								return (
									<div key={i}>
										<Link className={"group flex flex-col p-2 bg-gray-100"} to={"/app/packages/" + pkgID + "/" + lesson?._id} key={i}>
											{lesson?.thumbnail &&
											<div className={"bg-gray-200 h-48 w-full rounded-sm"} alt={lesson?.thumbnail}>
												<img src={lesson?.thumbnail} alt="random" className={"h-48 rounded-sm object-contain mx-auto"}/>
											</div>
											}
											<div className={"text-black text-xl text-gray-600 pt-1"}>{lesson?.title}</div>
											<div className={"text-black text-sm text-gray-400"}>{lesson?.description}</div>
										</Link>
									</div>
								)
							})}
						</div>
						:
						<>
							{error ?
								<div className="flex w-full h-full justify-center items-center">
									<div className="text-base font-medium text-gray-500 py-16">{error}</div>
								</div>
								:
								<div className="flex w-full h-full justify-center items-center">
									<div className="text-base font-medium text-gray-500 py-16">There are no lessons available to you.</div>
								</div>
							}
						</>
					:
					<div className="flex w-full h-full justify-center items-center">
						<RotateSpinner size={30} color="#374151" loading={loading}/>
					</div>
				}
			</App>
		);
	}
;

export default Package;
