import React, {useEffect, useState} from 'react';
import App from "layouts/app";
import {get} from "../../../class/helper";
import {Link} from "react-router-dom";
import {RotateSpinner} from "react-spinners-kit";

const Packages = () => {
		const [achievements, setAchievements] = useState(null);
		const [error, setError] = useState(null);
		const [loading, setLoading] = useState(true);

		useEffect(() => {
			get("/achievements", true).then(r => {
				if (r.error) {
					setError(r.data)
					setLoading(false)
				} else {
					setAchievements(r.data.achievements)
					setLoading(false)
				}
			})
		}, []);


		return (
			<App>
				{!loading ?
					<>
						{achievements?.length > 0 ?
							<div className={"grid lg:grid-cols-4 grid-cols-1 gap-2 w-full p-2"}>
								{achievements.map((achievement, i) => {
									return (
										<div key={i}>
											<div className={"group flex flex-col p-2 bg-gray-100 text-center"} key={i}>
												{achievement.image &&
												<div className={"bg-gray-200 h-48 w-full rounded-sm"}>
													<img src={achievement.image} alt={achievement.title} className={"h-48 rounded-sm object-contain mx-auto"}/>
												</div>
												}
												<div className={"text-black text-xl text-gray-600"}>{achievement.title}</div>
												<div className={"text-black text-sm text-gray-400"}>{achievement.description}</div>
											</div>
										</div>
									)
								})}
							</div>
							:
							<>
								{error ?
									<div className="flex w-full h-full justify-center items-center">
										<div className="text-base font-medium text-gray-500 py-16">{error}</div>
									</div>
									:
									<div className="flex w-full h-full justify-center items-center">
										<div className="text-base font-medium text-gray-500 py-16">You do not have any achievements.</div>
									</div>
								}
							</>
						}
					</>
					:
					<div className="flex w-full h-full justify-center items-center">
						<RotateSpinner size={30} color="#374151" loading={loading}/>
					</div>
				}
			</App>
		);
	}
;

export default Packages;
