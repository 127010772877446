import React, {useEffect, useState} from 'react';
import Admin from "layouts/admin";
import {get} from "../../../class/helper";
import {Link} from "react-router-dom";
import {RotateSpinner} from "react-spinners-kit";

const ListPages = () => {
	const [pages, setPages] = useState(null);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		get("/admin/pages", true).then(r => {
			if (r.error) {
				setError(r.data)
				setLoading(false)
			} else {
				setPages(r.data.pages)
				setLoading(false)
			}
		})
	}, []);


	return (
		<Admin title={"List Pages"}>
			{!loading ?
				<>
					{pages?.length > 0 ?
						<>
							<div className={"grid lg:grid-cols-3 grid-cols-1 gap-2 w-full p-2"}>
								{pages.map((page, i) => {
									return (
										<div key={i}>
											<Link className={"group flex flex-col p-2 bg-gray-100"} to={"/admin/pages/" + page._id}>
												<div className={"text-black text-xl text-gray-600"}>{page.title}</div>
												<div className={"text-black text-sm text-gray-400"}>{page.description.length > 48 ? page.description.slice(0, 48) + "..." : page.description}</div>
											</Link>
										</div>
									)
								})}
							</div>

						</>
						:
						<>
							{error ?
								<div className="flex w-full h-full justify-center items-center">
									<div className="text-base font-medium text-gray-500 py-16">{error}</div>
								</div>
								:
								<div className="flex w-full h-full justify-center items-center">
									<div className="text-base font-medium text-gray-500 py-16">There are no pages available to edit.</div>
								</div>
							}
						</>
					}
				</>
				:
				<div className="flex w-full h-full justify-center items-center">
					<RotateSpinner size={30} color="#374151" loading={loading}/>
				</div>
			}
		</Admin>
	);
};


export default ListPages;
