import {Link, useHistory, useLocation} from "react-router-dom";

import Layout from "layouts/layout";
import {get} from "class/helper";

import {HiOutlineCollection, HiOutlineDocumentText, HiOutlineFolder, HiOutlineHome, HiOutlineLogout, HiOutlineSparkles, HiOutlineUserGroup} from "react-icons/hi";
import {useEffect, useState} from "react";
import {RotateSpinner} from "react-spinners-kit";

export default function App({title, children}) {
	const location = useLocation();
	const history = useHistory();

	const [user, setUser] = useState(null);
	const [error, setError] = useState(null);

	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setLoading(true)
		get("/user", true).then(r => {
			if (r.error) {
				setError(r.data)
			} else {
				setUser(r.data.user)
			}
			setLoading(false)
		})
	}, []);


	let colour = "#374151"
	let size = 20

	let links = [
		{
			icon: <HiOutlineHome color={colour} size={size}/>,
			name: "Dashboard",
			link: "/app/packages",
			action: "redirect",
			admin: true
		},
		{
			icon: <HiOutlineUserGroup color={colour} size={size}/>,
			name: "Users",
			link: "/admin/users",
			action: "redirect",
		},
		{
			icon: <HiOutlineCollection color={colour} size={size}/>,
			name: "Lessons",
			link: "/admin/lessons",
			action: "redirect",
		},
		{
			icon: <HiOutlineFolder color={colour} size={size}/>,
			name: "Packages",
			link: "/admin/packages",
			action: "redirect",
		},
		{
			icon: <HiOutlineSparkles color={colour} size={size}/>,
			name: "Achievements",
			link: "/admin/achievements",
			action: "redirect",
		},
		{
			icon: <HiOutlineDocumentText color={colour} size={size}/>,
			name: "Pages",
			link: "/admin/pages",
			action: "redirect",
		},
		{
			icon: <HiOutlineLogout color={colour} size={size}/>,
			name: "Logout",
			action: "button",
			actionButton: (e) => {
				e.preventDefault();
				localStorage.clear();
				history.push("/")
			},
		},
	];

	let curLink = links[0];
	links.map((link) => {
		if (location.pathname === link.link) {
			curLink = link;
		}
	});

	return (
		<Layout title={title}>
			<div className="w-full h-full px-4">
				<div className={"flex flex-col md:flex-row max-w-screen-xl mx-auto py-4 gap-4"}>
					<div className="px-0 py-6 flex flex-col lg:flex-row gap-2 w-full h-full">
						<div className="flex flex-row w-full lg:w-1/4">
							<div className="h-full w-full bg-gray-50 rounded-sm text-xl font-semibold">
								<div className="flex flex-col w-full h-full pt-2">
									<div className="w-full rounded-sm text-center text-lg font-medium py-2 mb-4 justify-center items-center text-gray-200 bg-blue-800">Menu</div>
									{loading ?
										<div className={"m-auto pt-32"}>
											<RotateSpinner size={30} color="#374151" loading={loading}/>
										</div>
										:
										<div className="flex flex-col gap-2 px-2">
											{links.map((link, i) => {
												if (link.action === "redirect") {
													return (
														<div key={i}>
															{link?.admin ?
																<>
																	{user?.admin &&
																	<Link className={"group flex flex-row w-full rounded-sm text-gray-900 text-center text-lg font-medium p-2 justify-start items-center gap-3 cursor-pointer " + (location.pathname === link.link ? "bg-gray-100 bg-gray-100" : "bg-gray-50 hover:bg-gray-100")} to={link.link}>
																		<div className={"flex w-10 h-10 rounded-full bg-gray-200 items-center justify-center cursor-pointer " + (location.pathname === link.link ? "bg-gray-200" : "bg-gray-100 group-hover:bg-gray-200")}>{link.icon}</div>
																		<div className="font-medium text-lg text-gray-600">{link.name}</div>
																	</Link>
																	}
																</>
																:
																<Link className={"group flex flex-row w-full rounded-sm text-gray-900 text-center text-lg font-medium p-2 justify-start items-center gap-3 cursor-pointer " + (location.pathname === link.link ? "bg-gray-100 bg-gray-100" : "bg-gray-50 hover:bg-gray-100")} to={link.link} key={i}>
																	<div className={"flex w-10 h-10 rounded-full bg-gray-200 items-center justify-center cursor-pointer " + (location.pathname === link.link ? "bg-gray-200" : "bg-gray-100 group-hover:bg-gray-200")}>{link.icon}</div>
																	<div className="font-medium text-lg text-gray-600">{link.name}</div>
																</Link>
															}
														</div>
													);
												} else if (link.action === "button") {
													return (
														<button className={"group flex flex-row w-full rounded-sm text-gray-900 text-center text-lg font-medium p-2 justify-start items-center gap-3 cursor-pointer " + (location.pathname === link.link ? "bg-gray-100 bg-gray-100" : "bg-gray-50 hover:bg-gray-100")} onClick={link.actionButton} key={i}>
															<div className={"flex w-10 h-10 rounded-full bg-gray-200 items-center justify-center cursor-pointer " + (location.pathname === link.link ? "bg-gray-200" : "bg-gray-100 group-hover:bg-gray-200")}>{link.icon}</div>
															<div className="font-medium text-lg text-gray-600">{link.name}</div>
														</button>
													);
												}
											})}
										</div>
									}
								</div>
							</div>
						</div>
						<div className="flex flex-col h-full w-full lg:w-3/4 bg-gray-50 rounded-sm text-xl font-semibold">
							<div className="w-full h-16 pt-2">
								<div className="w-full rounded-sm text-center text-lg font-medium py-2 mb-4 text-gray-200 bg-blue-800">{title ? title : curLink.name}</div>
							</div>
							{error ?
								<div className="flex w-full h-full justify-center items-center">
									<div className="text-base font-medium text-gray-500 py-16">{error ? error : "An error has occurred while processing your request."}</div>
								</div>
								:
								<>
									{children}
								</>
							}
						</div>
					</div>
				</div>
			</div>
		</Layout>
	)
}