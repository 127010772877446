import React, {useEffect, useState} from 'react';
import App from "layouts/app";
import {get} from "../../../class/helper";
import {useParams} from "react-router-dom";
import {RotateSpinner} from "react-spinners-kit";
import DOMPurify from 'dompurify';
import Plyr from "plyr-react";

const Lesson = () => {
	const [lesson, setLesson] = useState(null);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);

	const {package: pkgID, lesson: lessonID} = useParams()

	useEffect(() => {
		get("/packages/" + pkgID + "/lessons/" + lessonID, true).then(r => {
			if (r.error) {
				setError(r.data)
				setLoading(false)
			} else {
				setLesson(r.data.lesson)
				setLoading(false)
			}
		})
	}, [pkgID, lessonID]);


	return (
		<App title={loading ? "Loading..." : (lesson?.title ? lesson.title : "Error")}>
			{!loading ? lesson ?
					<div className="w-full h-full px-4">
						<div className={"flex flex-col md:flex-row max-w-screen-xl mx-auto py-4 gap-4"}>
							<div className="flex flex-col lg:flex-row gap-1 w-full h-full">
								<div className="flex flex-col h-full w-full bg-gray-50 rounded-sm text-xl font-semibold gap-4">
									{validURL(lesson.video) &&
									<div className="w-full h-full">
										<Plyr source={{
											type: "video",
											sources: [
												{
													src: lesson.video,
													provider: "youtube"
												}
											]
										}}/>
									</div>
									}
									{lesson?.content ?
										<div className="flex w-full h-full justify-center items-center bg-gray-200 rounded-sm p-4">
											<div className="text-base font-medium text-gray-900 p-4" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(lesson.content)}}/>
										</div>
										:
										<div className="flex w-full h-full justify-center items-center">
											<div className="text-base font-medium text-gray-500 py-16">This lesson does not have content yet.</div>
										</div>
									}
								</div>
							</div>
						</div>
					</div>
					:
					<>
						{error ?
							<div className="flex w-full h-full justify-center items-center">
								<div className="text-base font-medium text-gray-500 py-16">{error}</div>
							</div>
							:
							<div className="flex w-full h-full justify-center items-center">
								<div className="text-base font-medium text-gray-500 py-16">The current lesson is unavailable.</div>
							</div>
						}
					</>
				:
				<div className="flex w-full h-full justify-center items-center">
					<RotateSpinner size={30} color="#374151" loading={loading}/>
				</div>
			}
		</App>
	);
};

function validURL(str) {
	let pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
		'((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
		'((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
		'(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
		'(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
		'(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
	return !!pattern.test(str);
}

export default Lesson;
