import React, {useEffect, useState} from 'react';
import Layout from "layouts/layout";

import Paul from "assets/images/paul.jpg"
import Brendan from "assets/images/brendan.png"
import {get} from "../class/helper";
import {toast} from "react-hot-toast";

const Contact = () => {
	const [team, setTeam] = useState({});
	const [one, setOne] = useState({});
	const [two, setTwo] = useState({});

	useEffect(() => {
		get("/pages/615e170d5fe2598f055fec62", true).then(r => {
			if (r.error) {
				toast.error(r.data)
			} else {
				setTeam(r.data.page)
			}
		})
		get("/pages/615e17151bda0d45241a7869", true).then(r => {
			if (r.error) {
				toast.error(r.data)
			} else {
				setOne(r.data.page)
			}
		})
		get("/pages/615e171e2346a68aa97d7537", true).then(r => {
			if (r.error) {
				toast.error(r.data)
			} else {
				setTwo(r.data.page)
			}
		})
	}, []);

	return (
		<Layout>
			<section>
				<section className="flex h-[200px] bg-gradient-to-b from-gray-50 to-gray-100">
					<div className={"w-full h-full max-w-screen-xl m-auto p-2"}>
						<div className={"flex flex-col h-full w-full items-center justify-center"}>
							<h2 className="mt-6 text-3xl font-base text-gray-800 md:text-5xl mb-3">{team.title}</h2>
							<p className="text-xl font-base tracking-wider text-gray-600">{team.description}</p>
						</div>
					</div>
				</section>
				<section className={"flex flex-col lg:flex-row w-full h-full max-w-screen-xl justify-center items-center m-auto gap-6 lg:gap-8 my-6 lg:my-32"}>
					<div className="flex flex-col items-center justify-center bg-gray-50 py-12 px-24 shadow-sm rounded-lg w-96">
						<div className="inline-flex shadow-lg border border-gray-200 rounded-full overflow-hidden h-40 w-40">
							<img src={Paul} className={"m-auto"} alt={"paul"}/>
						</div>

						<h2 className="mt-4 font-bold text-xl text-gray-800">{one.title}</h2>
						<h6 className="mt-2 text-sm font-medium text-gray-600">{one.description}</h6>
					</div>
					<div className="flex flex-col items-center justify-center bg-gray-50 py-12 px-24 shadow-sm rounded-lg w-96">
						<div className="inline-flex shadow-lg border border-gray-200 rounded-full overflow-hidden h-40 w-40">
							<img src={Brendan} className={"m-auto"} alt={"brendan"}/>
						</div>

						<h2 className="mt-4 font-bold text-xl text-gray-800">{two.title}</h2>
						<h6 className="mt-2 text-sm font-medium text-gray-600">{two.description}</h6>
					</div>
				</section>
			</section>
		</Layout>
	);
};

export default Contact;