import Header from "layouts/components/header";
import Footer from "layouts/components/footer";

export default function Layout({children, title}) {
	document.title = title ? `LearnMagic101 - ${title}` : `LearnMagic101: Learn More, Learn Magic`

	return (
		<div className="flex flex-col min-h-screen">
			<Header/>
			<main className="relative flex flex-col w-full flex-1">
				{children}
			</main>
			<Footer/>
		</div>
	);
}