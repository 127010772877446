import React from 'react';
import Layout from "layouts/layout";

const Contact = () => {
	return (
		<Layout>
			<section className="flex h-[200px] bg-gradient-to-b from-gray-50 to-gray-100">
				<div className={"w-full h-full max-w-screen-xl m-auto p-2"}>
					<div className={"flex flex-col h-full w-full items-center justify-center"}>
						<h2 className="mt-6 text-3xl font-base text-gray-800 md:text-5xl mb-3">Contact us</h2>
						<p className="text-xl font-base tracking-wider text-gray-600">Have an enquiry? Contact us here.</p>
					</div>
				</div>
			</section>
			<section className="h-full flex flex-col justify-center my-5 mx-2">
				<div className="p-0 mx-auto w-full max-w-4xl">
					<div className="bg-gray-50 shadow-sm w-full rounded-sm">
						<form className="px-5 py-7">
							<label className="font-medium text-base text-gray-600 pb-1 block" htmlFor={"name"}>Full Name</label>
							<input id={"name"} name={"name"} type="text" placeholder={"John Doe"} className="border rounded-sm px-3 py-2 mt-1 mb-5 text-sm w-full"/>
							<label className="font-medium text-base text-gray-600 pb-1 block" htmlFor={"email"}>Email Address</label>
							<input id={"email"} name={"email"} type="email" placeholder={"example@gmail.com"} className="border rounded-sm px-3 py-2 mt-1 mb-5 text-sm w-full"/>
							<label className="font-medium text-base text-gray-600 pb-1 block" htmlFor={"phone"}>Phone Number</label>
							<input id={"phone"} name={"phone"} type="tel" placeholder={"+447123456789"} className="border rounded-sm px-3 py-2 mt-1 mb-5 text-sm w-full"/>
							<label className="font-medium text-base text-gray-600 pb-1 block" htmlFor={"message"}>Your Message</label>
							<textarea id={"message"} name={"message"} placeholder={"Write your enquiry here"} rows={"8"} className="border rounded-sm px-3 py-2 mt-1 mb-5 text-sm w-full"/>
							<button type="submit" className="w-full bg-blue-800 transition duration-200 ease-in-out hover:bg-blue-900 rounded-sm py-2 text-lg text-white font-medium">Send</button>
						</form>
					</div>
				</div>
			</section>
		</Layout>
	);
};

export default Contact;