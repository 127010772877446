import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';

import ListUsers from "pages/admin/users/list"
import NewUser from "pages/admin/users/new"
import EditUser from "pages/admin/users/edit"

import ListLessons from "pages/admin/lessons/list"
import NewLesson from "pages/admin/lessons/new"
import EditLesson from "pages/admin/lessons/edit"

import ListPackages from "pages/admin/packages/list"
import NewPackage from "pages/admin/packages/new"
import EditPackage from "pages/admin/packages/edit"

import ListAchievements from "pages/admin/achivements/list"
import NewAchievement from "pages/admin/achivements/new"
import EditAchievement from "pages/admin/achivements/edit"

import ListPages from "../pages/admin/pages/list";
import EditPage from "../pages/admin/pages/edit";

let Router = ({location, match}) => (
	<Switch location={location}>
		<Redirect from={`${match.path}/`} to={"/admin/users"} exact/>

		<Route path={`${match.path}/users`} component={ListUsers} exact/>
		<Route path={`${match.path}/users/new`} component={NewUser} exact/>
		<Route path={`${match.path}/users/:user`} component={EditUser} exact/>

		<Route path={`${match.path}/lessons`} component={ListLessons} exact/>
		<Route path={`${match.path}/lessons/new`} component={NewLesson} exact/>
		<Route path={`${match.path}/lessons/:lesson`} component={EditLesson} exact/>

		<Route path={`${match.path}/packages`} component={ListPackages} exact/>
		<Route path={`${match.path}/packages/new`} component={NewPackage} exact/>
		<Route path={`${match.path}/packages/:pkg`} component={EditPackage} exact/>

		<Route path={`${match.path}/achievements`} component={ListAchievements} exact/>
		<Route path={`${match.path}/achievements/new`} component={NewAchievement} exact/>
		<Route path={`${match.path}/achievements/:achievement`} component={EditAchievement} exact/>

		<Route path={`${match.path}/pages`} component={ListPages} exact/>
		<Route path={`${match.path}/pages/:page`} component={EditPage} exact/>

		<Route path={`*`} component={() =>
			<div>404 page not found</div>}
		/>
	</Switch>
);

export default Router