import React, {useEffect, useState} from 'react';
import Admin from "layouts/admin";
import {get, post} from "../../../class/helper";
import Select from "react-select";
import {useHistory} from "react-router-dom";
import {toast} from "react-hot-toast";

const NewPackage = () => {
	let history = useHistory()

	const [title, setTitle] = useState("");
	const [description, setDescription] = useState("");

	const [lessons, setLessons] = useState([]);
	const [lessonsSelected, setLessonsSelected] = useState([]);
	const [lessonsSearch, setLessonsSearch] = useState("");

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	useEffect(() => {
		setLoading(true)
		get("/admin/lessons", true).then(r => {
			if (r.error) {
				toast.error(r.data)
				setError(r.data)
			} else {
				let lessons = r.data.lessons
				setLessons(lessons)
			}
		})
		setLoading(false)
	}, []);


	const handleSubmit = (e) => {
		e.preventDefault()

		let lessonsSelectedParsed = []
		if (lessonsSelected?.length > 0) {
			lessonsSelected.forEach(lessonsSelected => {
				lessonsSelectedParsed.push(lessonsSelected.value)
			})
		}

		post(`/admin/packages`, {
			title,
			description,
			lessons: lessonsSelectedParsed
		}, true).then(() =>
			history.push("/admin/packages")
		)
	};

	let fields = [
		{
			name: "title",
			input: setTitle,
			type: "text",
			value: title
		}, {
			name: "description",
			input: setDescription,
			type: "text",
			value: description
		}
	]

	function MakeOption(x) {
		return {value: x._id, label: x.title};
	}

	return (
		<Admin title={"Create Package"}>
			<form className="flex flex-col rounded px-8 py-6 overflow-hidden" onSubmit={handleSubmit}>
				<div className={"flex flex-col lg:flex-row"}>
					<div className={"flex flex-col mr-auto"}>
						{fields.map((field, index) => (
							<div key={index} className={"flex flex-col w-96"}>
								<label className="font-medium tracking-wide text-gray-700 text-md" htmlFor={field.name}>{field.name.charAt(0).toUpperCase() + field.name.slice(1)}</label>
								<input id={field.name} name={field.name} className="items-center font-normal h-12 px-4 w-full bg-gray-100 text-gray-600 mt-2 rounded focus:outline-none focus:ring-1 ring-blue-600" type={field.type} onChange={(e) => field.input(e.target.value)} value={field.value} placeholder={field.name.charAt(0).toUpperCase() + field.name.slice(1)}/>
							</div>
						))}
					</div>
					<div className={"w-96"}>
						<label className="font-medium tracking-wide text-gray-700 text-md pr-2" htmlFor={"lessons"}>Lessons</label>
						{lessons?.length > 0 ?
							<Select isMulti value={lessonsSelected} name="lessons" options={lessons.map(lesson => MakeOption(lesson))} classNamePrefix="select" closeMenuOnSelect={false} onInputChange={(value) => setLessonsSearch(value)} inputValue={lessonsSearch} onChange={(selected) => {
								setLessonsSelected(selected)
							}}/>
							:
							<div className="flex font-medium tracking-wide text-gray-700 text-md bg-gray-200 rounded p-8 justify-center items-center mt-2">There are no lessons available</div>
						}
					</div>
				</div>
				<div className={"flex flex-row mt-auto ml-auto w-64 justify-end items-center"}>
					<button className="flex items-center justify-center rounded-sm text-xl font-medium bg-blue-700 hover:bg-blue-800 text-gray-100 hover:text-gray-200 focus:outline-none focus:ring-0 ml-auto mt-8 w-28 h-12" type={"submit"}>Update</button>
				</div>
			</form>
		</Admin>
	);
};


export default NewPackage;
