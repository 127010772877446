import React, {useState} from 'react';
import Admin from "layouts/admin";
import {post} from "../../../class/helper";
import {useHistory} from "react-router-dom";
import {toast} from "react-hot-toast";
import {convertToHTML} from "draft-convert";
import {Editor} from "react-draft-wysiwyg";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const NewLesson = () => {
	let history = useHistory()

	const [title, setTitle] = useState("");
	const [description, setDescription] = useState("");
	const [thumbnail, setThumbnail] = useState("");
	const [video, setVideo] = useState("");
	const [content, setContent] = useState(null);

	const handleSubmit = async (e) => {
		e.preventDefault()
		let contentHTML = convertToHTML(content.getCurrentContent())

		post(`/admin/lessons`, {
			title,
			description,
			thumbnail,
			video,
			content: contentHTML,
		}, true).then((data) => {
			if (data.error) {
				toast.error(data.data)
			}

			history.push("/admin/lessons")
		})
	};

	let fields = [
		{
			name: "title",
			input: setTitle,
			type: "text",
			value: title
		}, {
			name: "description",
			input: setDescription,
			type: "text",
			value: description
		}, {
			name: "thumbnail",
			input: setThumbnail,
			type: "text",
			value: thumbnail
		}, {
			name: "video",
			input: setVideo,
			type: "text",
			value: video
		}
	]

	return (
		<Admin title={"Create Lesson"}>
			<form className="flex flex-col rounded overflow-hidden py-2 px-2" onSubmit={handleSubmit}>
				<div className={"flex flex-col lg:flex-row"}>
					<div className={"flex flex-col mr-auto gap-4"}>
						{fields.map((field, index) => (
							<div key={index} className={"flex flex-col"}>
								<label className="font-medium tracking-wide text-gray-700 text-md" htmlFor={field.name}>{field.name.charAt(0).toUpperCase() + field.name.slice(1)}</label>
								<input id={field.name} name={field.name} className="items-center font-normal h-12 px-4 w-full bg-gray-100 text-gray-600 mt-2 rounded focus:outline-none focus:ring-1 ring-blue-600" type={field.type} onChange={(e) => field.input(e.target.value)} value={field.value} placeholder={field.name.charAt(0).toUpperCase() + field.name.slice(1)}/>
							</div>
						))}
						<label className="font-medium tracking-wide text-gray-700 text-md pt-4" htmlFor={"content"}>Content</label>
						<div className={"bg-gray-100 rounded p-2 text-gray-900 max-w-screen-2xl w-full"}>
							<Editor editorState={content} onEditorStateChange={setContent}/>
						</div>
					</div>
					<div className={"flex flex-row mt-auto ml-auto w-64 justify-end items-center"}>
						<button className="flex items-center justify-center rounded-sm text-xl font-medium bg-blue-700 hover:bg-blue-800 text-gray-100 hover:text-gray-200 focus:outline-none focus:ring-0 ml-auto mt-8 w-28 h-12" type={"submit"}>Update</button>
					</div>
				</div>
			</form>
		</Admin>
	);
};


export default NewLesson;
