import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';

import ListPackages from "pages/app/package/packages"
import ViewPackage from "pages/app/package/package"
import ViewLesson from "pages/app/package/lesson"

import ListAchievements from "pages/app/achievements/achievements";

import Settings from "pages/app/user/settings"

let Router = ({location, match}) => (
	<Switch location={location}>
		<Redirect from={`${match.path}/`} to={"/app/packages"} exact/>
		<Route path={`${match.path}/packages`} component={ListPackages} exact/>
		<Route path={`${match.path}/packages/:package`} component={ViewPackage} exact/>
		<Route path={`${match.path}/packages/:package/:lesson`} component={ViewLesson} exact/>

		<Route path={`${match.path}/achievements`} component={ListAchievements} exact/>

		<Route path={`${match.path}/settings`} component={Settings} exact/>

		<Route path={`*`} component={() =>
			<div>404 page not found</div>}
		/>
	</Switch>
);

export default Router