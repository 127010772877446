import Layout from "layouts/layout";
import {Link} from "react-router-dom";
import Experts from "assets/images/svgs/experts.svg"
import Education from "assets/images/svgs/education.svg"
import Magic from "assets/images/svgs/magic.svg"
import Hello from "assets/images/svgs/hello.svg"
import ReactPlayer from "react-player";
import React, {useEffect, useState} from "react";
import {get} from "../class/helper";
import {toast} from "react-hot-toast";
//import 'plyr/dist/plyr.css'

const Services = () => {
	const [services, setServices] = useState({});
	const [one, setOne] = useState({});
	const [two, setTwo] = useState({});
	const [three, setThree] = useState({});
	const [four, setFour] = useState({});
	const [five, setFive] = useState({});
	const [interest, setInterest] = useState({});

	useEffect(() => {
		get("/pages/615e1693d63026a9788b62a1", true).then(r => {
			if (r.error) {
				toast.error(r.data)
			} else {
				setServices(r.data.page)
			}
		})

		get("/pages/615e169dde807811cbb3ff1e", true).then(r => {
			if (r.error) {
				toast.error(r.data)
			} else {
				setOne(r.data.page)
			}
		})

		get("/pages/615e16a7e8b7b62a7ac008da", true).then(r => {
			if (r.error) {
				toast.error(r.data)
			} else {
				setTwo(r.data.page)
			}
		})

		get("/pages/615e16b0381fb5f554993a7b", true).then(r => {
			if (r.error) {
				toast.error(r.data)
			} else {
				setThree(r.data.page)
			}
		})

		get("/pages/615e16e9dcd6b99cd1d0fb17", true).then(r => {
			if (r.error) {
				toast.error(r.data)
			} else {
				setFour(r.data.page)
			}
		})

		get("/pages/615e16f6f236975b718e23ed", true).then(r => {
			if (r.error) {
				toast.error(r.data)
			} else {
				setFive(r.data.page)
			}
		})

		get("/pages/615e16fe44938466f20170c9", true).then(r => {
			if (r.error) {
				toast.error(r.data)
			} else {
				setInterest(r.data.page)
			}
		})
	}, []);

	return (
		<Layout>
			<section className="flex h-[200px] bg-gradient-to-b from-gray-50 to-gray-100">
				<div className={"w-full h-full max-w-screen-xl m-auto p-2"}>
					<div className={"flex flex-col h-full w-full items-center justify-center"}>
						<h2 className="mt-6 text-3xl font-base text-gray-800 md:text-5xl mb-3">{services.title}</h2>
						<p className="text-xl font-base tracking-wider text-gray-600">{services.description}</p>
					</div>
				</div>
			</section>
			<section className="my-8">
				<div className="flex flex-col w-full h-full gap-8">
					<div className="flex flex-col lg:flex-row w-full h-full m-auto max-w-screen-xl gap-2 p-2 bg-white">
						<div className="flex flex-col w-full lg:w-1/2 gap-2 py-12 lg:max-w-lg">
							<div className={"w-full lg:text-left font-semibold text-2xl text-gray-800 text-center"}>{one.title}</div>
							<div className={"w-full lg:text-left font-medium text-base text-gray-600 text-center"}>{one.description}</div>
						</div>
						<div className="hidden lg:block lg:w-1/2 justify-center items-center p-8">
							<ReactPlayer url='https://youtu.be/JBQRDiFG_fU'/>
						</div>
					</div>
				</div>
				<div className="flex flex-col w-full h-full gap-8">
					<div className="flex flex-col lg:flex-row w-full h-full m-auto max-w-screen-xl gap-2 p-2 bg-white">
						<div className="hidden lg:flex lg:w-1/2 justify-center items-center">
							<img src={Experts} className="h-64" draggable="false" alt="example"/>
						</div>
						<div className="flex flex-col w-full lg:w-1/2 gap-2 py-12 lg:max-w-lg">
							<div className={"w-full lg:text-left font-semibold text-2xl text-gray-800 text-center"}>{two.title}</div>
							<div className={"w-full lg:text-left font-medium text-base text-gray-600 text-center"}>{two.description}</div>
						</div>
					</div>
				</div>
				<div className="flex flex-col w-full h-full gap-8">
					<div className="flex flex-col lg:flex-row w-full h-full m-auto max-w-screen-xl gap-2 p-2 bg-white ">
						<div className="flex flex-col w-full lg:w-1/2 gap-2 py-12 lg:max-w-lg">
							<div className={"w-full lg:text-left font-semibold text-2xl text-gray-800 text-center"}>{three.title}</div>
							<div className={"w-full lg:text-left font-medium text-base text-gray-600 text-center"}>{three.description}</div>
						</div>
						<div className="hidden lg:flex lg:w-1/2 justify-center items-center">
							<img src={Education} className="h-64" draggable="false" alt="example"/>
						</div>
					</div>
				</div>
			</section>
			<section className="my-8">
				<div className="flex flex-col w-full h-full gap-8">
					<div className="flex flex-col lg:flex-row w-full h-full m-auto max-w-screen-xl gap-2 p-2 bg-white ">
						<div className="hidden lg:flex lg:w-1/2 justify-center items-center">
							<img src={Magic} className="h-64" draggable="false" alt="example"/>
						</div>
						<div className="flex flex-col w-full lg:w-1/2 gap-2 py-12 lg:max-w-lg">
							<div className={"w-full lg:text-left font-semibold text-2xl text-gray-800 text-center"}>{four.title}</div>
							<div className={"w-full lg:text-left font-medium text-base text-gray-600 text-center"}>{four.description}</div>
						</div>
					</div>
				</div>
				<div className="flex flex-col w-full h-full gap-8">
					<div className="flex flex-col lg:flex-row w-full h-full m-auto max-w-screen-xl gap-2 p-2 bg-white ">
						<div className="flex flex-col w-full lg:w-1/2 gap-2 py-12 lg:max-w-lg">
							<div className={"w-full lg:text-left font-semibold text-2xl text-gray-800 text-center"}>{five.title}</div>
							<div className={"w-full lg:text-left font-medium text-base text-gray-600 text-center"}>{five.description}</div>
						</div>
						<div className="hidden lg:flex lg:w-1/2 justify-center items-center">
							<img src={Hello} className="h-64" draggable="false" alt="example"/>
						</div>

					</div>
				</div>
			</section>
			<section className="bg-gray-50 py-8 px-4">
				<div className="flex lg:flex-row flex-col bg-gray-50 max-w-screen-xl m-auto">
					<div className="flex flex-col w-full h-full m-auto gap-1">
						<div className={"w-full text-center lg:text-left font-semibold text-xl text-gray-800"}>{interest.title}</div>
						<div className={"w-full text-center lg:text-left font-medium text-sm text-gray-600"}>{interest.description}</div>
					</div>
					<Link className={"flex text-lg font-semibold w-48 justify-center items-center bg-blue-600 rounded-sm text-gray-100"} to="/contact">Get in touch</Link>
				</div>
			</section>
		</Layout>
	);
}

export default Services;