import {Link} from "react-router-dom";

export default function Footer() {
	return (
		<footer className="bg-white border-t border-gray-300 text-gray-800 w-full px-4 h-20 md:h-14 z-10">
			<div className="flex flex-col md:flex-row w-full h-full gap-2 md:gap-4 max-w-screen-xl m-auto">
				<div className="flex mx-auto md:ml-0 md:mr-auto pt-3 md:pt-0">
					<div className="flex justify-center items-center mr-auto text-sm md:text-base">
						Copyright © 2021 LearnMagic101. All rights reserved.
					</div>
				</div>
				<div className="flex mx-auto md:ml-auto md:mr-0 pb-3 md:pb-0">
					<Link to="/" className="flex justify-center items-center text-sm md:text-base">
						Privacy Policy
					</Link>
				</div>
			</div>
		</footer>
	);
}