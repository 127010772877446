import React, {useEffect, useState} from 'react';
import Admin from "layouts/admin";
import {get} from "../../../class/helper";
import {Link} from "react-router-dom";
import {RotateSpinner} from "react-spinners-kit";

const ListUsers = () => {
	const [lessons, setLessons] = useState(null);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		get("/admin/achievements", true).then(r => {
			if (r.error) {
				setError(r.data)
				setLoading(false)
			} else {
				setLessons(r.data.achievements)
				setLoading(false)
			}
		})
	}, []);


	return (
		<Admin title={"List Achievements"}>
			{!loading ?
				<>
					{lessons?.length > 0 ?
						<>
							<div className={"grid lg:grid-cols-3 grid-cols-1 gap-2 w-full p-2"}>
								{lessons.map((achievement, i) => {
									return (
										<div key={i}>
											<Link className={"group flex flex-col p-2 bg-gray-100"} to={"/admin/achievements/" + achievement._id}>
												<div className={"text-black text-xl text-gray-600"}>{achievement.title}</div>
												<div className={"text-black text-sm text-gray-400"}>{achievement.description}</div>
											</Link>
										</div>
									)
								})}
							</div>

						</>
						:
						<>
							{error ?
								<div className="flex w-full h-full justify-center items-center">
									<div className="text-base font-medium text-gray-500 py-16">{error}</div>
								</div>
								:
								<div className="flex w-full h-full justify-center items-center">
									<div className="text-base font-medium text-gray-500 py-16">There are no achievements.</div>
								</div>
							}
						</>
					}
				</>
				:
				<div className="flex w-full h-full justify-center items-center">
					<RotateSpinner size={30} color="#374151" loading={loading}/>
				</div>
			}
			<div className={"w-full p-2"}>
				<Link to={"/admin/achievements/new"} className={"flex w-full h-10 bg-blue-800 hover:bg-blue-900 text-white rounded-sm ml-auto items-center justify-center"}>New</Link>
			</div>
		</Admin>
	);
};


export default ListUsers;
