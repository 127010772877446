import React, {useEffect, useState} from 'react';
import Admin from "layouts/admin";
import {deletes, get, patch} from "../../../class/helper";
import {useHistory, useParams} from "react-router-dom";
import {toast} from "react-hot-toast";

const EditLesson = () => {
	let history = useHistory()

	let {page} = useParams()

	const [title, setTitle] = useState("");
	const [description, setDescription] = useState("");

	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setLoading(true)
		get("/admin/pages/" + page, true).then(r => {
			if (r.error) {
				toast.error(r.data)
				setError(r.data)
				history.push("/admin/pages")
			} else {
				setLoading(true)
				let page = r.data.page

				setTitle(page.title)
				setDescription(page.description)
				setLoading(false)
			}
		})
		setLoading(false)
	}, []);

	const handleSubmit = (e) => {
		e.preventDefault()

		patch(`/admin/pages/${page}`, {
			title,
			description,
		}, true, 201).then(r => {
			if (r.error) {
				toast.error(r.data)
				setError(r.data)
			}
			history.push("/admin/pages")
		})
	};

	let fields = [
		{
			name: "title",
			input: setTitle,
			type: "text",
			value: title
		}
	]

	return (
		<Admin title={"Edit Page"}>
			<>
				{page ?
					<form className="flex flex-col rounded overflow-hidden py-2 px-2 w-full" onSubmit={handleSubmit}>
						<div className={"flex flex-col lg:flex-row w-full"}>
							<div className={"flex flex-col mr-auto gap-4 w-full"}>
								{fields.map((field, index) => (
									<div key={index} className={"flex flex-col w-full"}>
										<label className="font-medium tracking-wide text-gray-700 text-md w-full" htmlFor={field.name}>{field.name.charAt(0).toUpperCase() + field.name.slice(1)}</label>
										<input id={field.name} name={field.name} className="items-center font-normal h-12 px-4 w-full bg-gray-100 text-gray-600 mt-2 rounded focus:outline-none focus:ring-1 ring-blue-600 w-full" type={field.type} onChange={(e) => field.input(e.target.value)} value={field.value} placeholder={field.name.charAt(0).toUpperCase() + field.name.slice(1)}/>
									</div>
								))}
								<div className={"flex flex-col w-full"}>
									<label className="font-medium tracking-wide text-gray-700 text-md w-full" htmlFor={"description"}>Description</label>
									<textarea id={"description"} name={"description"} className="items-center font-normal h-12 px-4 w-full bg-gray-100 text-gray-600 mt-2 rounded focus:outline-none focus:ring-1 ring-blue-600 w-full h-48" type={"text"} onChange={(e) => setDescription(e.target.value)} value={description} placeholder={"description"}/>
								</div>
							</div>
						</div>
						<div className={"flex flex-row mt-auto ml-auto w-64 justify-end items-center"}>
							<button className="flex items-center justify-center rounded-sm text-xl font-medium bg-blue-700 hover:bg-blue-800 text-gray-100 hover:text-gray-200 focus:outline-none focus:ring-0 ml-auto mt-8 w-28 h-12" type={"submit"}>Update</button>
						</div>
					</form>
					:
					<div className={"flex flex-row mt-auto ml-auto w-64 justify-end items-center"}>
						<button className="flex items-center justify-center rounded-sm text-xl font-medium bg-blue-700 hover:bg-blue-800 text-gray-100 hover:text-gray-200 focus:outline-none focus:ring-0 ml-auto mt-8 w-28 h-12" type={"submit"}>Update</button>
					</div>
				}
			</>
		</Admin>
	);
};


export default EditLesson;
