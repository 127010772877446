import React from 'react'

import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import {Toaster} from 'react-hot-toast'

import Home from "pages/home";
import Services from "pages/services";
import About from "pages/about";
import Contact from "pages/contact";

import AuthRouter from "routes/auth";
import AppRouter from "routes/app"
import AdminRouter from "routes/admin";

function App() {
	return (
		<>
			<Toaster position="top-right" reverseOrder={false}/>
			<Router>
				<Switch>
					<Route path={`/`} component={Home} exact/>
					<Route path={`/services`} component={Services} exact/>
					<Route path={`/about`} component={About} exact/>
					<Route path={`/contact`} component={Contact} exact/>

					<Route path={`/auth`} component={AuthRouter}/>
					<Route path={`/app`} component={AppRouter}/>
					<Route path={`/admin`} component={AdminRouter}/>

					<Route path={`*`} component={() =>
						<div>404 page not found</div>}
					/>
				</Switch>
			</Router>
		</>
	);
}

export default App;