import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';

import Login from "pages/auth/login"

// eslint-disable-next-line import/no-anonymous-default-export
export default ({location, match}) => (
	<Switch location={location}>
		<Redirect from={`${match.path}/`} to={"/auth/login"} exact/>
		<Route path={`${match.path}/login`} component={Login}/>

		<Route path={`*`} component={() => <div>404 page not found</div>}/>
	</Switch>
);