import React, {useState} from 'react';
import {Link, useLocation} from "react-router-dom";
import {HiMenu, HiOutlineX} from "react-icons/hi";

import Logo from "assets/images/logo.svg"

const Header = () => {
	const location = useLocation();
	let [menu, setMenu] = useState(false);

	let links = [
		{
			name: "Home",
			link: "/"
		},
		{
			name: "Services",
			link: "/services"
		},
		{
			name: "About",
			link: "/about"
		},
		{
			name: "Contact",
			link: "/contact"
		}
	]

	let token = localStorage.getItem("token")

	return (
		<>
			<header className="bg-white border-b border-gray-300 w-full h-14 md:px-4 z-10">
				<div className="flex flex-row w-full h-full gap-4 max-w-screen-xl m-auto text-black">
					<div className="flex justify-center items-center mr-auto md:mr-0">
						<img className="w-14 h-14 p-3" src={Logo} alt=""/>
						<Link className="hidden md:flex text-lg font-normal justify-center items-center" to={"/"}>{process.env.REACT_APP_NAME}</Link>
					</div>
					<Link className={"flex md:hidden m-auto text-center text-xl"} to={"/"}>{process.env.REACT_APP_NAME}</Link>
					<div className="hidden md:flex flex-row mr-auto">
						{links.map((link, i) =>
							<Link className={"flex h-full w-24 justify-center items-center border-b-2 " + (location.pathname === link.link ? "border-blue-600" : "border-white hover:border-gray-200")} to={link.link} key={i}>{link.name}</Link>
						)}
					</div>
					<button className="flex md:hidden justify-center items-center text-gray-900 hover:text-gray-700 hover:cursor-pointer ml-auto w-14 h-14 text-3xl" onClick={() => setMenu(!menu)}>
						{menu ? <HiOutlineX/> : <HiMenu/>}
					</button>
					<Link className="hidden md:flex ml-auto px-6 my-2 justify-center items-center rounded-sm bg-blue-800 hover:bg-blue-900 text-white transition duration-200 ease-in-out font-medium" to={token ? "/app" : "/auth/login"}>{token ? "Dashboard" : "Login"}</Link>
				</div>
			</header>
			{menu && <header className="flex flex-col bg-white border-b border-gray-300 w-full">
				{links.map((link, i) => (
					<Link className={"border-l-4 pl-2 text-lg my-auto py-3 " + (location.pathname === link.link ? "border-blue-600" : "border-white hover:border-gray-200")} to={link.link} key={i}>{link.name}</Link>
				))}
				<hr/>
				<Link className={"border-l-4 pl-2 text-lg my-auto py-3 " + (location.pathname === "/auth/login" ? "border-blue-600" : "border-white hover:border-gray-200")} to={token ? "/app" : "/auth/login"}>{token ? "Dashboard" : "Login"}</Link>
			</header>}
		</>
	);
};

export default Header;
