// Libraries
import {Link} from "react-router-dom";

// Internal
import Layout from "layouts/layout";
import ReactPlayer from 'react-player'
import React, {useEffect, useState} from "react";
import {get} from "../class/helper";
import {toast} from "react-hot-toast";

const Home = () => {
	const [home, setHome] = useState({});
	const [one, setOne] = useState({});
	const [two, setTwo] = useState({});
	const [three, setThree] = useState({});

	useEffect(() => {
		get("/pages/615caba35677a8d5861159b5", true).then(r => {
			if (r.error) {
				toast.error(r.data)
			} else {
				setHome(r.data.page)
			}
		})
		get("/pages/615e1650f7511237e51aae20", true).then(r => {
			if (r.error) {
				toast.error(r.data)
			} else {
				setOne(r.data.page)
			}
		})
		get("/pages/615e1659bcac144f86bdb8fb", true).then(r => {
			if (r.error) {
				toast.error(r.data)
			} else {
				setTwo(r.data.page)
			}
		})
		get("/pages/615e166a097fed2ee7ed391c", true).then(r => {
			if (r.error) {
				toast.error(r.data)
			} else {
				setThree(r.data.page)
			}
		})
	}, []);

	return (
		<Layout>
			<section className="h-[620px] bg-gradient-to-b from-gray-50 to-gray-100" id={"home"}>
				<div className="flex w-full h-full max-w-screen-xl items-center mx-auto px-4 gap-32">
					<div className="flex flex-col w-full xl:w-1/2 gap-8">
						<h1 className="text-gray-700 text-3xl font-medium">{home?.title}</h1>
						<p className="text-gray-600 text-xl font-normal">{home?.description}</p>
						<div className="flex">
							<Link className="bg-blue-700 text-gray-100 font-medium text-lg py-3 w-full text-center rounded-sm transition duration-200 ease-in-out hover:bg-blue-800 hover:border-gray-200" to={"/services"}>Learn More</Link>
						</div>
					</div>
					<div className="hidden xl:block w-1/2 justify-end">
						{/*<images src={"/assets/images/work.svg"} className="h-full" draggable="false" alt="header-image"/>*/}
						<ReactPlayer className={"w-full h-full rounded-sm"} url='https://youtu.be/vTyr2OzYpqc'/>
					</div>
				</div>
			</section>
			<section className="bg-gray-50">
				<div className="max-w-screen-xl mx-auto px-4 py-16">
					<div className="grid grid-cols-1 grid-rows-3 sm:grid-cols-1 sm:grid-rows-3 md:grid-cols-3 md:grid-rows-1 gap-8 text-gray-700">
						<div>
							<div className="pb-2 text-gray-900">
								<h2 className="text-xl font-medium">{one.title}</h2>
							</div>
							<div>
								<p>{one.description}</p>
							</div>
						</div>
						<div>
							<div className="pb-2 text-gray-900">
								<h2 className="text-xl font-medium">{two.title}</h2>
							</div>
							<div>
								<p>{two.description}</p>
							</div>
						</div>
						<div>
							<div className="pb-2 text-gray-900">
								<h2 className="text-xl font-medium">{three.title}</h2>
							</div>
							<div>
								<p>{three.description}</p>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/*<section className={"h-[800px] bg-white"}></section> -->*/}
		</Layout>
	);
}

export default Home;