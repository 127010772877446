import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import {toast} from "react-hot-toast";

import {post} from "class/helper";

import Layout from "layouts/layout";

export default function Login() {
	const history = useHistory()

	let [username, setUsername] = useState("")
	let [password, setPassword] = useState("")
	const [loading, setLoading] = useState(false);

	const handleSubmit = async (e) => {
		e.preventDefault()

		setLoading(e)

		await post("/auth/login", {
			username,
			password,
		}, false, 200).then((r) => {
			if (r.error) {
				toast.error(r.data)
				setLoading(false)
			} else {
				localStorage.setItem("token", r.data.token)
				setLoading(false)
				history.push("/app")
			}
		})
	};

	return (
		<Layout title={"Login"}>
			<div className="h-full md:mt-32 flex flex-col justify-center pt-20 mx-2">
				<div className="p-0 mx-auto w-full max-w-md">
					<h1 className="font-medium text-center text-2xl text-gray-700 py-6 bg-gray-100 rounded-t-xl">Login</h1>
					<hr/>
					<div className="bg-gray-100 shadow-sm w-full rounded-sm">
						<form className="px-5 py-7" onSubmit={handleSubmit}>
							<label className="font-medium text-base text-gray-600 pb-1 block" htmlFor={"username"}>Username</label>
							<input id={"username"} name={"username"} type="text" className="border rounded-sm px-3 py-2 mt-1 mb-5 text-sm w-full" onChange={(e) => setUsername(e.target.value)}/>

							<label className="font-medium text-base text-gray-600 pb-1 block" htmlFor={"password"}>Password</label>
							<input id={"password"} name={"password"} type="password" className="border rounded-sm px-3 py-2 mt-1 mb-5 text-sm w-full" onChange={(e) => setPassword(e.target.value)}/>

							<button type="submit" disabled={loading} className="w-full bg-blue-600 rounded-sm py-2 text-lg text-white font-medium">{loading ? "Loading..." : "Login"}</button>
						</form>
					</div>
				</div>
			</div>
		</Layout>
	);
}