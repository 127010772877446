import axios from "axios";

/*const fetcher = async (url) => {
	let returnValue = ""
	let token = localStorage.getItem("token")

	await axios.get(process.env.REACT_APP_API + url, {
		headers: {
			'Content-Type': 'application/json',
			'Authorization': token
		}
	}).then((res) => {
		returnValue = res.data
	}).catch((e) => {
		if (e?.response?.data?.error) {
			throw e.response.data.error
		} else {
			throw "An error has occurred while processing your request."
		}
	})

	return returnValue
}*/

const post = async (url, data = {}, incAuth = true) => {
	let headers = {
		'Content-Type': 'application/json',
	}

	let token = localStorage.getItem("token")

	if (incAuth) {
		headers = {
			'Content-Type': 'application/json',
			'Authorization': token
		}
	}

	let returnValue = {error: true, data: "An error has occurred while processing your request."}
	try {
		await axios.post(`${process.env.REACT_APP_API}${url}`, JSON.stringify(data), {
			headers
		}).then((res) => {
			returnValue = {error: false, data: res.data}
		})
	} catch (e) {
		if (e?.response?.data?.error) {
			returnValue = {error: true, data: e.response.data.error}
		} else {
			returnValue = {error: true, data: "An error has occurred while processing your request."}
		}
	}

	return returnValue
}

const get = async (url = {}, incAuth = true) => {
	let headers = {
		'Content-Type': 'application/json',
	}

	let token = localStorage.getItem("token")

	if (incAuth) {
		headers = {
			'Content-Type': 'application/json',
			'Authorization': token
		}
	}

	let returnValue = {error: true, data: "An error has occurred while processing your request."}

	try {
		await axios.get(`${process.env.REACT_APP_API}${url}`, {
			headers
		}).then((res) => {
			returnValue = {error: false, data: res.data}
		})
	} catch (e) {
		if (e?.response?.data?.error) {
			returnValue = {error: true, data: e.response.data.error}
		} else {
			returnValue = {error: true, data: "An error has occurred while processing your request."}
		}
	}

	return returnValue
}

const patch = async (url, data = {}, incAuth = true) => {
	let headers = {
		'Content-Type': 'application/json',
	}

	let token = localStorage.getItem("token")

	if (incAuth) {
		headers = {
			'Content-Type': 'application/json',
			'Authorization': token
		}
	}

	let returnValue = {error: true, data: "An error has occurred while processing your request."}
	try {
		await axios.patch(`${process.env.REACT_APP_API}${url}`, JSON.stringify(data), {
			headers
		}).then((res) => {
			returnValue = {error: false, data: res.data}
		})
	} catch (e) {
		if (e?.response?.data?.error) {
			returnValue = {error: true, data: e.response.data.error}
		} else {
			returnValue = {error: true, data: "An error has occurred while processing your request."}
		}
	}

	return returnValue
}

const deletes = async (url, incAuth = true) => {
	let headers = {
		'Content-Type': 'application/json',
	}

	let token = localStorage.getItem("token")

	if (incAuth) {
		headers = {
			'Content-Type': 'application/json',
			'Authorization': token
		}
	}

	let returnValue = {error: true, data: "An error has occurred while processing your request."}

	try {
		await axios.delete(`${process.env.REACT_APP_API}${url}`, {
			headers
		}).then((res) => {
			returnValue = {error: false, data: res.data}
		})
	} catch (e) {
		if (e?.response?.data?.error) {
			returnValue = {error: true, data: e.response.data.error}
		} else {
			returnValue = {error: true, data: "An error has occurred while processing your request."}
		}
	}

	return returnValue
}

export {post, get, patch, deletes}